import React from 'react'
// import ReactDOM from 'react-dom'
// import PropTypes from 'prop-types'
const axios = require('axios');

import Autosuggest from 'react-autosuggest';

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  if (value === undefined) {
    return []
  }

  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength == 0) {
    return [];
  }

  return axios.get('/drugs?q=' + inputValue).then(response => {
    return response.data && response.data.data || []
  });
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => {
  return suggestion.name
}

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
  let genericName = suggestion.generic_name

  let generic = ""

  if (suggestion.is_generic) {
    generic = "(Generic)"
  } else {
    generic = "(" + genericName +  ")"
  }

  let classification = ""
  if (suggestion.classification == 'non-prescription') {
    classification = "Non-Prescription"
  }

  return (<div>
    <span className='brand_name'>{suggestion.name}</span>
    <span className='generic_name'> {generic}</span>
    <div className='drug_classication' >{classification}</div>
  </div>)
};

class DrugSearch extends React.Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: []
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value).then(res => {
      this.setState({
        suggestions: res
      });
    })
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Search for a drug',
      value,
      onChange: this.onChange,
      name: "q"
    };

    const css = {
      boxShadow: "0 2px 11px -2px #ccc"
    };

    // Finally, render it!
    return (
      <Autosuggest
        style={css}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default DrugSearch
