import React, { Component } from 'react'
import Select from 'react-select'
import _ from 'lodash';

const DrugFilterContext = React.createContext();


const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#d7d4f5',
      primary: '#7d6ff1',
    },
  });



class DrugFilter extends React.Component {
    constructor(options) {
        super()
        this.loadData(options.allOptions, options.currentSelections, this.state)
    }

    loadData = (allOptions, selectedValues) => {
        var brandSelected, formSelected, strengthSelected, packageSelected, quantitySelected
        var brands = []
        var forms = []
        var strengths = []
        var packages = []
        var quantities = []

        _.sortBy(Object.keys(allOptions)).forEach(function (name) {
            var brandOption = { value: name, label: name }
            brands.push(brandOption)

            if (_.isEmpty(forms) && (name === selectedValues.brand || _.isEmpty(selectedValues.brand))) {
                brandSelected = brandOption
                const formOptions = allOptions[brandSelected.value]

                _.sortBy(Object.keys(formOptions)).forEach(function (name) {
                    var formOption = { value: name, label: name }
                    forms.push(formOption)

                    if (_.isEmpty(strengths) && (name === selectedValues.form || _.isEmpty(selectedValues.form))) {
                        formSelected = formOption
                        const strengthOptions = formOptions[formSelected.value]

                        _.sortBy(Object.keys(strengthOptions)).forEach(function (name) {
                            var strengthOption = { value: name, label: name }
                            strengths.push(strengthOption)

                            if (_.isEmpty(packages) && (name === selectedValues.strength || _.isEmpty(selectedValues.strength))) {
                                strengthSelected = strengthOption
                                const packageOptions = strengthOptions[strengthSelected.value]

                                _.sortBy(Object.keys(packageOptions)).forEach(function (name) {
                                    var packageOption = { value: name, label: name }
                                    packages.push(packageOption)

                                    if (_.isEmpty(quantities) && (name === selectedValues.package || _.isEmpty(selectedValues.package))) {
                                        packageSelected = packageOption
                                        const quantityOptions = packageOptions[packageSelected.value]

                                        _.sortBy(Object.keys(quantityOptions)).forEach(function (name) {
                                            var quantityOption = { value: name, label: name }
                                            quantities.push(quantityOption)
                                            quantities = _.sortBy(quantities, [function(quantity) { return quantity.name; }]);

                                            if ((name === selectedValues.quantity || _.isEmpty(selectedValues.quantity))) {
                                                quantitySelected = quantityOption
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })

        this.state = {
            allOptions,
            brands,
            brandSelected,
            forms,
            formSelected,
            strengths,
            strengthSelected,
            packages,
            packageSelected,
            quantities,
            quantitySelected
        }
    }

    redirectPage = () => {
        var url = new URL(window.location.href);
        url.pathname = "/drug";
        url.searchParams.set('q', this.state.brandSelected.value);
        url.searchParams.set('form', this.state.formSelected.value);
        url.searchParams.set('strength', this.state.strengthSelected.value);
        url.searchParams.set('package', this.state.packageSelected.value);
        url.searchParams.set('qty', this.state.quantitySelected.value);

        console.log(url)

        window.location.href = url
    }

    handleBrandChange = brandSelected => {
        this.loadData(this.state.allOptions, {
            brand: brandSelected.value,
        })

        this.setState(this.state);
        this.redirectPage();
    }

    handleFormChange = formSelected => {
        this.loadData(this.state.allOptions, {
            brand: this.state.brandSelected.value,
            form: formSelected.value,
        })

        this.setState(this.state);
        this.redirectPage();
    }

    handleStrengthChange = strengthSelected => {
        this.loadData(this.state.allOptions, {
            brand: this.state.brandSelected.value,
            form: this.state.formSelected.value,
            strength: strengthSelected.value,
        })

        this.setState(this.state);
        this.redirectPage();
    }

    handlePackageChange = packageSelected => {
        this.loadData(this.state.allOptions, {
            brand: this.state.brandSelected.value,
            form: this.state.formSelected.value,
            strength: this.state.strengthSelected.value,
            package: packageSelected.value,
        })

        this.setState(this.state);
        this.redirectPage();
    }

    handleQtyChange = qtySelected => {
        this.loadData(this.state.allOptions, {
            brand: this.state.brandSelected.value,
            form: this.state.formSelected.value,
            strength: this.state.strengthSelected.value,
            package: this.state.packageSelected.value,
            quantity: qtySelected.value,
        })

        this.setState(this.state);
        this.redirectPage();
    }

    divStyle = {
        float: 'left',
        width: '250px',
    };

    filterGroupStyle = {
        border: "1px solid rgb(225, 225, 225)",
        display: "inline-block",
        borderRadius: "4px",
        boxShadow: "1px 1px 5px 0px rgb(225, 225, 225)",
        MozBoxShadow: "1px 1px 5px 0px rgb(225, 225, 225)",
        WebkitBoxShadow: "1px 1px 5px 0px rgb(225, 225, 225)",
        paddingBottom: "10px",
    };

    filterStyle = {
        padding: "5px 15px",
        borderRight: "1px solid #eee"
    }

    lastFilterStyle = {
        padding: "5px 15px",
        borderRight: "none",
    }

    labelStyle = {
        fontSize: "16px",
        color: "#888",
        paddingTop: "5px"
    }

    render() {
        return (
            <div className="container" style={this.filterGroupStyle} >
                <div className="row" role="group">
                    <div className="col-12 col-md-6 col-lg" style={this.filterStyle}>
                        <label style={this.labelStyle} >Brand</label>
                        <Select value={this.state.brandSelected} options={this.state.brands} onChange={this.handleBrandChange} theme={theme} />
                    </div>
                    <div className="col-12 col-md-6 col-lg" style={this.filterStyle}>
                        <label style={this.labelStyle} >Form</label>
                        <Select value={this.state.formSelected} options={this.state.forms} onChange={this.handleFormChange} theme={theme} />
                    </div>
                    <div className="col-12 col-md-6 col-lg" style={this.filterStyle}>
                        <label style={this.labelStyle} >Strength</label>
                        <Select value={this.state.strengthSelected} options={this.state.strengths} onChange={this.handleStrengthChange} theme={theme} />
                    </div>

                    <div className="col-12 col-md-6 col-lg" style={this.filterStyle}>
                        <label style={this.labelStyle} >Package</label>
                        <Select value={this.state.packageSelected} options={this.state.packages} onChange={this.handlePackageChange} theme={theme} />
                    </div>
                    <div className="col-12 col-md-6 col-lg" style={this.lastFilterStyle}>
                        <label style={this.labelStyle} >Quantity</label>
                        <Select value={this.state.quantitySelected} options={this.state.quantities} onChange={this.handleQtyChange} theme={theme} />
                    </div>
                </div>
            </div>)
        }
    }

    export default DrugFilter
